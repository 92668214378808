import {sendForm} from '/src/lib/cms/directus.ts';

export const initForm = (formId, modalId, feedback) => {

  //console.log("newsletter form", {formId, modalId, feedback});
  const form = document.getElementById(formId);
  if(form === null) return;
  //console.log("contactForm ->init dropdown");

  
  if(form) form.addEventListener('submit', async (e) => {
    e.preventDefault();
    //console.log("contactForm->submitted", form);
    const formData = new FormData(form);

    const data = {...Object.fromEntries(formData.entries()), gdpr_accepted: true, };

    //console.log("contactForm->submitted", {cur: dropdownButtonCaption.textContent, def: dropdownButtonCaption.dataset.default});
    sendForm(data, "newsletter").then((response) => {
      console.log("response", data, response);
      if (response?.ok) {
        form.reset();

        //alert('Form submitted successfully');
        if(modalId) {
          const modal = document.getElementById(modalId + "-close");
          //console.log("contactForm.js", modal);
          modal?.click();
        }
	  	  window?.notifications.show(feedback?.success, 'success', 'check', false);
      } else {
        window?.notifications.show(feedback?.error, 'warning', 'warning', false);
      }
    });

  });
  
}

