
const defaultConfig : any = {
  DIRECTUS_URL: 'https://atp.directus.app/'
};

const getEnv = (key: string): any => {
    if (typeof window !== 'undefined') {
      // Browser environment
      return (import.meta.env as any)[key] || defaultConfig[key];
    } else {
      // Server environment
      return process.env[key] || (import.meta.env as any)?.[key];
      
    }
  };
  
  const parseBoolean = (value: any): boolean => {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
    return Boolean(value);
  };


  
  const config = {
    SMART_RECRUITERS_API_URL: "https://api.smartrecruiters.com/v1/companies/ATParchitekteningenieure/postings",
    DIRECTUS_URL: getEnv('DIRECTUS_URL'), // || 'https://atp.directus.app/',
    USE_PAGE_CACHE: parseBoolean(getEnv('USE_PAGE_CACHE')),
    STORE_TO_PAGE_CACHE: parseBoolean(getEnv('STORE_TO_PAGE_CACHE')),
    IS_STATIC: parseBoolean(getEnv('IS_STATIC')),
    IS_DEV: parseBoolean(getEnv('DEV')),
    IS_PROD: parseBoolean(getEnv('PROD')),
    DID_UPDATE: !(parseBoolean(getEnv('IS_STATIC'))),
    HOSTNAME: getEnv('URL') || 'https://www.atp.ag',
    BRANCH: getEnv('BRANCH_NAME'),
  };
  if (typeof window === 'undefined') console.log("CREATE CONFIG", {config, context: process.env});
  
  export default config;