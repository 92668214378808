class PubSubService {

    subscribers: { [key: string]: Function[] };

    constructor() {
        this.subscribers = {};
    }

    // Subscribe to an event
    subscribe(eventName:string, callback:Function) {
        const self = this;
        if (!this.subscribers[eventName]) {
            this.subscribers[eventName] = [];
        }
        this.subscribers[eventName].push(callback);
        return function() {
            //console.log("unsubscribing", {eventName});
            const index = self.subscribers[eventName].indexOf(callback);
            if (index > -1) {
                self.subscribers[eventName].splice(index, 1);
            }
         };
    }

    // Publish an event (notify all subscribers)
    publish(eventName:string, data:any) {
        if (!this.subscribers[eventName]) return;

        this.subscribers[eventName].forEach(callback => {
            callback(data);
        });
    }
}

if (typeof window !== 'undefined') {
    window.PubSubService = new PubSubService();
}