import anime from "animejs/lib/anime.es.js";

let servicesInteractiveInstance = null

export const init = (componentId, startThing) => {  
    //console.log("startThing->init", startThing);
    if(document.getElementById(componentId) === null) return;
    let duration = 500;
    let thingActive;

    let things = {
        A: {
        name: "A",
        thing: document.querySelector("#" + componentId  + " .thingA"),
        subs: document.querySelector("#" + componentId  + " .thingA .thingSubs"),
        line: document.querySelectorAll("#" + componentId  + " .thingA .thingLine"),
        lineOffHeight: "5vw",
        lineOnHeight: "16vw",
        text: document.querySelectorAll("#" + componentId  + " .thingA .thingText"),
        textOffTranslateX: "5vw",
        textOffTranslateY: "0",
        textOnTranslateX: "0",
        textOnTranslateY: "0",
        main: document.querySelector("#" + componentId  + " .thingA .thingMain"),
        header: document.querySelector("#" + componentId  + " .thingA .thingHeader"),
        title: document.querySelector("#" + componentId  + " .thingA .thingTitle"),
        titleOffColor: "#FFFFFF",
        titleOnColor: "#FFFFFF",
        circle: document.querySelector("#" + componentId  + " .thingA .thingCircle"),
        circleOffColor: "#898989",
        circleOnColor: "#cc0033",
        statusMouse: 0,
        statusActive: 0,
        OffTranslateX: "0vw",
        OffTranslateY: "0vw",
        AOnTranslateX: "-5vw",
        AOnTranslateY: "0vw",
        BOnTranslateX: "4vw",
        BOnTranslateY: "0vw",
        COnTranslateX: "0vw",
        COnTranslateY: "5vw",
        DOnTranslateX: "0vw",
        DOnTranslateY: "-5vw",
        },
        B: {
        name: "B",
        thing: document.querySelector("#" + componentId  + " .thingB"),
        subs: document.querySelector("#" + componentId  + " .thingB .thingSubs"),
        line: document.querySelectorAll("#" + componentId  + " .thingB .thingLine"),
        lineOffHeight: "5vw",
        lineOnHeight: "16vw",
        text: document.querySelectorAll("#" + componentId  + " .thingB .thingText"),
        textOffTranslateX: "5vw",
        textOffTranslateY: "0",
        textOnTranslateX: "0",
        textOnTranslateY: "0",
        main: document.querySelector("#" + componentId  + " .thingB .thingMain"),
        mainAOffRight: "12vw",
        mainAOnRight: "-30vw",
        header: document.querySelector("#" + componentId  + " .thingB .thingHeader"),
        title: document.querySelector("#" + componentId  + " .thingB .thingTitle"),
        titleOffColor: "#898989",
        titleOnColor: "#1e83ba",
        circle: document.querySelector("#" + componentId  + " .thingB .thingCircle"),
        circleOffColor: "#e7e7e7",
        circleOnColor: "#1e83ba",
        statusMouse: 0,
        statusActive: 0,
        OffTranslateX: "0vw",
        OffTranslateY: "0vw",
        AOnTranslateX: "21.5vw",
        AOnTranslateY: "0vw",
        BOnTranslateX: "3vw",
        BOnTranslateY: "0vw",
        COnTranslateX: "0vw",
        COnTranslateY: "5vw",
        DOnTranslateX: "0vw",
        DOnTranslateY: "-5vw",
        },
        C: {
        name: "C",
        thing: document.querySelector("#" + componentId  + " .thingC"),
        subs: document.querySelector("#" + componentId  + " .thingC .thingSubs"),
        line: document.querySelectorAll("#" + componentId  + " .thingC .thingLine"),
        lineOffHeight: "5vw",
        lineOnHeight: "16vw",
        text: document.querySelectorAll("#" + componentId  + " .thingC .thingText"),
        textOffTranslateX: "-15vw",
        textOffTranslateY: "0",
        textOnTranslateX: "0",
        textOnTranslateY: "0",
        main: document.querySelector("#" + componentId  + " .thingC .thingMain"),
        header: document.querySelector("#" + componentId  + " .thingC .thingHeader"),
        title: document.querySelector("#" + componentId  + " .thingC .thingTitle"),
        titleOffColor: "#898989",
        titleOnColor: "#cc8d00",
        circle: document.querySelector("#" + componentId  + " .thingC .thingCircle"),
        circleOffColor: "#e7e7e7",
        circleOnColor: "#cc8d00",
        statusMouse: 0,
        statusActive: 0,
        OffTranslateX: "0vw",
        OffTranslateY: "-11vw",
        AOnTranslateX: "-8vw",
        AOnTranslateY: "-14vw",
        BOnTranslateX: "3vw",
        BOnTranslateY: "-11vw",
        COnTranslateX: "0vw",
        COnTranslateY: "-7vw",
        DOnTranslateX: "0vw",
        DOnTranslateY: "-16vw",
        },
        D: {
        name: "D",
        thing: document.querySelector("#" + componentId  + " .thingD"),
        subs: document.querySelector("#" + componentId  + " .thingD .thingSubs"),
        line: document.querySelectorAll("#" + componentId  + " .thingD .thingLine"),
        lineOffHeight: "5vw",
        lineOnHeight: "16vw",
        text: document.querySelectorAll("#" + componentId  + " .thingD .thingText"),
        textOffTranslateX: "0",
        textOffTranslateY: "-5vw",
        textOnTranslateX: "0",
        textOnTranslateY: "0",
        main: document.querySelector("#" + componentId  + " .thingD .thingMain"),
        header: document.querySelector("#" + componentId  + " .thingD .thingHeader"),
        title: document.querySelector("#" + componentId  + " .thingD .thingTitle"),
        titleOffColor: "#898989",
        titleOnColor: "#99c74b",
        circle: document.querySelector("#" + componentId  + " .thingD .thingCircle"),
        circleOffColor: "#e7e7e7",
        circleOnColor: "#99c74b",
        statusMouse: 0,
        statusActive: 0,
        OffTranslateX: "0vw",
        OffTranslateY: "11vw",
        AOnTranslateX: "-8vw",
        AOnTranslateY: "14vw",
        BOnTranslateX: "3vw",
        BOnTranslateY: "11vw",
        COnTranslateX: "0vw",
        COnTranslateY: "16vw",
        DOnTranslateX: "0vw",
        DOnTranslateY: "7vw",
        },
    };

    let A = things.A;
    let B = things.B;
    let C = things.C;
    let D = things.D;

    thingsListener(A);
    thingsListener(B);
    thingsListener(C);
    thingsListener(D);

    function thingsListener(thing) {
        thing.main?.addEventListener("mouseover", () => {
        thing.statusMouse = 1;
        updateMain(thing);
        });
        thing.main?.addEventListener("mouseout", () => {
        if (thing.statusActive == 0) {
            thing.statusMouse = 0;
            updateMain(thing);
        }
        });
        thing.main?.addEventListener("click", () => {
        if (thing.statusActive == 0) {
            thingsUpdate(thing);
        }
        });
    }

    function thingsUpdate(thingActive) {
        A.statusActive = A.statusMouse = 0;
        B.statusActive = B.statusMouse = 0;
        C.statusActive = C.statusMouse = 0;
        D.statusActive = D.statusMouse = 0;

        if (thingActive) {
        thingActive.statusActive = thingActive.statusMouse = 1;
        }

        updateEverything(A, thingActive);
        updateEverything(B, thingActive);
        updateEverything(C, thingActive);
        updateEverything(D, thingActive);
    }

    function updateEverything(thing, thingActive) {
        updateThing(thing, thingActive);
        updateLines(thing, thingActive);
        updateText(thing, thingActive);
        updateMain(thing, thingActive);
        updateTitle(thing, thingActive);
    }

    function updateThing(thing, thingActive) {
        if (thing == thingActive) {
        thing.thing.style.zIndex = "2";
        } else {
        thing.thing.style.zIndex = "1";
        }
        if (thingActive) {
        anime({
            targets: thing.thing,
            translateX: thing[thingActive.name + "OnTranslateX"],
            translateY: thing[thingActive.name + "OnTranslateY"],
            duration: duration * 2,
        });
        } else {
        anime({
            targets: thing.thing,
            translateX: thing.OffTranslateX,
            translateY: thing.OffTranslateY,
            duration: duration * 2,
        });
        }
        if (thing == B && thingActive == A) {
        thing.header.style.textAlign = "left";
        anime({
            targets: thing.header,
            right: thing.mainAOnRight,
            duration: duration,
            easing: "easeOutQuad",
        });
        } else if (thing == B) {
        thing.header.style.textAlign = "right";
        anime({
            targets: thing.header,
            right: thing.mainAOffRight,
            duration: duration,
            easing: "easeOutQuad",
        });
        }
    }

    function updateLines(thing, thingActive) {
        if (thing == thingActive) {
        anime({
            targets: thing.line,
            height: thing.lineOnHeight,
            opacity: 1,
            duration: duration * 2,
            easing: "easeOutQuad",
        });
        } else {
        anime({
            targets: thing.line,
            height: thing.lineOffHeight,
            opacity: 0,
            duration: duration * 2,
            easing: "easeOutQuad",
        });
        }
    }

    function updateText(thing, thingActive) {
        if (thing.subs) {
        if (thing == thingActive) {
            thing.subs.style.pointerEvents = "auto";
            anime({
            targets: thing.text,
            translateX: thing.textOnTranslateX,
            translateY: thing.textOnTranslateY,
            opacity: 1,
            duration: duration * 2,
            easing: "easeOutQuad",
            });
        } else {
            thing.subs.style.pointerEvents = "none";
            anime({
            targets: thing.text,
            translateX: thing.textOffTranslateX,
            translateY: thing.textOffTranslateY,
            opacity: 0,
            duration: duration,
            easing: "easeOutQuad",
            });
        }
        }
    }

    function updateMain(thing, thingActive = null) {
        if (thing.statusMouse == 1) {
        anime({
            targets: thing.title,
            color: thing.titleOnColor,
            duration: duration,
            easing: "easeOutQuad",
        });
        anime({
            targets: thing.circle,
            backgroundColor: thing.circleOnColor,
            scale: 1.1,
            duration: duration,
            easing: "easeOutQuad",
        });
        } else {
        anime({
            targets: thing.title,
            color: thing.titleOffColor,
            duration: duration,
            easing: "easeOutQuad",
        });
        anime({
            targets: thing.circle,
            backgroundColor: thing.circleOffColor,
            scale: 1,
            duration: duration,
            easing: "easeOutQuad",
        });
        }
    }  

    function updateTitle(thing, thingActive) {
        if (thing == thingActive) {
        thing.title.style.pointerEvents = "all";
        } else {
        thing.title.style.pointerEvents = "none";
        }
    }
   

    function thingsInit(thingActive) {
        //console.log("thingsInit: ", {thingActive, servicesInteractiveInstance});
        //if(servicesInteractiveInstance) servicesInteractiveInstance.reset();

        servicesInteractiveInstance = anime({
        targets: document.querySelectorAll(`#${componentId} .thing`),
        opacity: 1,
        duration: duration * 2,
        delay: anime.stagger(250),
        easing: "linear",
        });

        thingsUpdate(thingActive);
    }
    setTimeout(() => thingsInit(things?.[startThing]), 500);// empty, A, B, C; D
}