import {sendForm} from '/src/lib/cms/directus.ts';
import { Dropdown } from 'flowbite';

export const initForm = (formId, modalId, topic, feedback) => {

  //console.log("contactForm", {formId, modalId, topic});
  const form = document.getElementById(formId);
  if(form === null) return;
  //console.log("contactForm ->init dropdown");
  let dropdownButtonCaption;
  //console.log("contactForm->init dropdown", {dropdownButton, dropdownElement});
  if(!topic) {
    const dropdownButton = document.getElementById(formId + "dropdown-topic");
    const dropdownElement = document.getElementById(formId + '-dropdown-form-topic');
    const dropdown = new Dropdown(dropdownElement, dropdownButton);
    dropdownButtonCaption = document.getElementById(formId + '-dropdown-topic-caption');
    const categoryOptions = document.querySelectorAll('#' + formId + '-dropdown-form-topic li');
    

    categoryOptions.forEach(option => {
      option.addEventListener('click', (event) => {
        event.preventDefault();
        const selectedValue = event.target.getAttribute('data-value');
        const buttonText = event.target.textContent;

        dropdownButtonCaption.textContent = buttonText;
        dropdownButton.classList.remove("text-red", "border-red");
        //console.log('Selected category:', {selectedValue});
        
        dropdown.hide()
      });
    });

  }
  
  if(form) form.addEventListener('submit', async (e) => {
    e.preventDefault();
    //console.log("contactForm->submitted", form);
    const formData = new FormData(form);
    const fixedTopic = topic || dropdownButtonCaption.textContent;
    const data = {...Object.fromEntries(formData.entries()), type: "contact", topic: fixedTopic, gdpr_accepted: true, };

    //console.log("contactForm->submitted", {cur: dropdownButtonCaption.textContent, def: dropdownButtonCaption.dataset.default});
    if(!topic && dropdownButtonCaption.textContent === dropdownButtonCaption.dataset.default) dropdownButton.classList.add("text-red", "border-red");
    else sendForm(data).then((response) => {
      //console.log("response", data, response);
      if (response?.ok) {
        form.reset();
        if(!topic) dropdownButtonCaption.textContent = dropdownButtonCaption.dataset.default;
        //alert('Form submitted successfully');
        if(modalId) {
          const modal = document.getElementById(modalId + "-close");
          //console.log("contactForm.js", modal);
          modal?.click();
        }
	  	  window?.notifications.show(feedback?.success, 'success', 'check', false);
      } else {
        window?.notifications.show(feedback?.error, 'warning', 'warning', false);
      }
    });
  });
  
}

