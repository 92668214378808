

export default class Notifications {
    constructor(options = {}) {
      this.options = {
        duration: options.duration || 3000,
        animation: options.animation || 'fade',
      };
      this.container = document.getElementById("notifications-container");
    }
  
    
  
    show(message, type = 'info', icon = 'warning', closable = false) {
      const notification = this.createNotification(message, type, icon, closable);
      this.container.appendChild(notification);
  
      if (this.options.animation === 'fade') {
        setTimeout(() => {
          notification.style.opacity = '1';
        }, 10);
      }
  
      if (this.options.duration > 0) {
        setTimeout(() => {
          this.close(notification);
        }, this.options.duration);
      }
    }
  
    createNotification(message, type, icon, closable) {
    const borderColor = this.getTypeClass(type);
    const iconClass = this.getIconClass(type);
      const notification = document.createElement('div');
      notification.className = `max-w-dvw-75 lg:max-w-dvw-50 px-2 py-1.5 lg:px-5 lg:py-2 bg-white rounded-md shadow-lg border fixed right-5 bottom-5 lg:right-12 0lg:bottom-12 z-600 ${borderColor} ${this.getTypeClass(type)}`;
  
      const iconEl = icon ? `<div class="shrink-0">
                    <img class="transition-all size-6 lg:size-8 filter-${iconClass}" src="/imgs/icons/phosphor_light/${icon}-light.svg" />
                    </div>` : '';
        const button = closable ? `<button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close"><span class="sr-only">Close</span><svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></button>` : '';
      const content = `
            <div class="flex flex-nowrap justify-between items-center gap-2.5 lg:gap-5">
                <div class="flex grow items-center gap-2.5 lg:gap-5">
                    ${iconEl}
                    <span class="text-base">${message}</span>
                </div>
                <div class="self-start shrink-0">
                ${button}
                </div>

            </div>

      `;
  
      notification.innerHTML = content;
  
      if (closable) {
        const closeButton = notification.querySelector('button');
        closeButton.addEventListener('click', () => this.close(notification));
      }
  
      return notification;
    }
  
    getTypeClass(type) {
      switch (type) {
        case 'success':
          return 'border-green';
        case 'warning':
          return 'border-red';
        default:
          return 'border-gray-50';
      }
    }

    getIconClass(type) {
        switch (type) {
          case 'success':
            return 'green';
          case 'warning':
            return 'red';
          default:
            return 'border-gray-50';
        }
      }
  

  
    close(notification) {
      notification.style.opacity = '0';
      setTimeout(() => {
        this.container.removeChild(notification);
      }, 300);
    }
  }
  
  // Usage example
  
  
  // Show different types of notifications
//   notifier.show('This is an info message', 'info');
//   notifier.show('Success! Your action was completed.', 'success');
//   notifier.show('Warning: Please check your input.', 'warning');
//   notifier.show('Error: Something went wrong.', 'error');