export const navigation = {
  "/de/": {
    title: "Home",
    subTitle: null, 
    backgroundImage: [Object],
    bigApplyButton: true
  },
  "/de/atp": {
    title: "ATP",
    subTitle: "kennenlernen",
    backgroundImage: null
  },
  "/de/atp/kultur": {
    title: "Unsere Kultur",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/organisation": {
    title: "Unsere Organisation",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/prozess": {
    title: "Unser Prozess",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/technologie": {
    title: "Unsere Technologie",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/verantwortung": {
    title: "Unsere Verantwortung",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/standorte": {
    title: "Standorte",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/atp/standort": {
    title: "Standort",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/leistungen": {
    title: "Leistungen",
    subTitle: "nutzen",
    backgroundImage: null,
  },
  "/de/leistungen/leistung": {
    title: "Leistung",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/projekte": {
    title: "Projekte",
    subTitle: "betrachten",
    backgroundImage: null,
  },
  "/de/projekte/projektkategorien": {
    title: "Projektkategorien",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/projekte/projektkategorie": {
    title: "Projektkategorie",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/projekte/projekt": {
    title: "Projekt",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/aktuelles": {
    title: "Aktuelles",
    subTitle: "erfahren",
    backgroundImage: null,
  },
  "/de/aktuelles/blogpost": {
    title: "Blogpost",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/aktuelles/newspost": {
    title: "Newspost",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/aktuelles/newsletter": {
    title: "Newsletter",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/aktuelles/publikationen": {
    title: "Publikationen",
    subTitle: null,
    backgroundImage: null,
  },
  "/de/karriere": {
    title: "Karriere",
    subTitle: "machen", 
    backgroundImage: null,
    bigApplyButton: true
  },
  "/de/karriere/warumatp": {
    title: "Warum ATP",
    subTitle: null, 
    backgroundImage: null
  },
  "/de/karriere/berufsfelder": {
    title: "Berufsfelder",
    subTitle: null, 
    backgroundImage: null
  },
  "/de/karriere/erfolgsgeschichten": {
    title: "Erfolgsgeschichten",
    subTitle: null, 
    backgroundImage: null
  },
  "/de/karriere/karrierechancen": {
    title: "Karrierechancen",
    subTitle: null, 
    backgroundImage: null
  },
  "/de/karriere/einstiegslevel": {
    title: "Einstiegslevel",
    subTitle: null, 
    backgroundImage: null
  },
  "/de/karriere/stellenmarkt": {
    title: "Stellenmarkt",
    subTitle: null, 
    backgroundImage: null
  },
};

export const subNavigation = {
  "/projekte/projektkategorien": { title: "Projektkategorien" },
  "/glossar": { title: "Glossar" },
  "/atp/standorte": { title: "Standorte" },
};


export const locations = [
  {
    id: 4,
    status: "published",
    sort: null,
    date_created: "2024-05-07T15:33:11.777Z",
    date_updated: null,
    mapx: 96.75,
    mapy: 110.25,
    image: {
      id: "a2a7490a-042d-4fd4-9ad5-15d29c2fdc7e",
      storage: "s3",
      filename_disk: "/imgs/media/slider_1_07_02.jpg",
      filename_download: "slider_1_07_01.jpg",
      title: "Slider 1 07 01",
      type: "image/jpeg",
      folder: "0ef78f77-72bf-4087-99de-048e1d64d8d9",
      uploaded_by: "da3d1bd5-6b59-4fdf-b0b7-ebcefcdd83a5",
      uploaded_on: "2024-04-16T12:26:41.648Z",
      modified_by: "da3d1bd5-6b59-4fdf-b0b7-ebcefcdd83a5",
      modified_on: "2024-04-26T08:04:13.216Z",
      charset: null,
      filesize: "434702",
      width: 2000,
      height: 1400,
      duration: null,
      embed: null,
      description: null,
      location: null,
      tags: null,
      metadata: {},
      focal_point_x: null,
      focal_point_y: null,
      is_public: true,
      is_logo: null,
      translations: [],
    },
    main_content: [],
    locations_id: 4,
    languages_code: "de-DE",
    name: "Budapest",
  },
  {
    id: 5,
    status: "published",
    sort: null,
    date_created: "2024-04-11T12:53:36.944Z",
    date_updated: "2024-05-07T15:33:27.346Z",
    mapx: 42.75,
    mapy: 110.25,
    image: {
      id: "fd1fe218-f53f-4e26-a0e2-2f6433eabbdf",
      storage: "s3",
      filename_disk: "/imgs/media/slider_1_07_03.jpg",
      filename_download: "image_landscape_07.jpg",
      title: "Image Landscape 07",
      type: "image/jpeg",
      folder: "0ef78f77-72bf-4087-99de-048e1d64d8d9",
      uploaded_by: "da3d1bd5-6b59-4fdf-b0b7-ebcefcdd83a5",
      uploaded_on: "2024-02-20T14:21:33.748Z",
      modified_by: "da3d1bd5-6b59-4fdf-b0b7-ebcefcdd83a5",
      modified_on: "2024-04-26T08:04:13.216Z",
      charset: null,
      filesize: "115827",
      width: 2120,
      height: 1484,
      duration: null,
      embed: null,
      description: null,
      location: null,
      tags: null,
      metadata: {},
      focal_point_x: null,
      focal_point_y: null,
      is_public: true,
      is_logo: null,
      translations: [2],
    },
    main_content: [],
    locations_id: 3,
    languages_code: "de-DE",
    name: "ATP Holding",
  },
];

// component sample Data

const sampleImages = [
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_01.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_02.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_03.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_04.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "l", filename_disk: "/imgs/media/slider_1_07_05.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_06.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 1484, height: 2120},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_07.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/slider_1_07_08.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "p", filename_disk: "/imgs/media/image_portrait_07.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 2120, height: 1484},
  {prop: "l", filename_disk: "/imgs/media/image_landscape_07.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 1484, height: 2120},
  {prop: "s", filename_disk: "/imgs/media/image_landscape_07.jpg", type: "image", id: Math.random().toString(36).substring(7), width: 1461, height: 1461},
];

export const logoImages = {
  "atp_logo_en_white_white.svg": {filename_disk: "/imgs/logos/atp_logo_en_white_white.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_en_white_red.svg": {filename_disk: "/imgs/logos/atp_logo_en_white_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_en_black_red.svg": {filename_disk: "/imgs/logos/atp_logo_en_black_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_de_white_white.svg": {filename_disk: "/imgs/logos/atp_logo_de_white_white.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_de_white_red.svg": {filename_disk: "/imgs/logos/atp_logo_de_white_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_de_black_red.svg": {filename_disk: "/imgs/logos/atp_logo_de_black_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 205, height: 73},
  "atp_logo_plain_white_white.svg": {filename_disk: "/imgs/logos/atp_logo_plain_white_white.svg", type: "image", id: Math.random().toString(36).substring(7), width: 103, height: 69},
  "atp_logo_plain_white_red.svg": {filename_disk: "/imgs/logos/atp_logo_plain_white_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 103, height: 69},
  "atp_logo_plain_black_red.svg": {filename_disk: "/imgs/logos/atp_logo_plain_black_red.svg", type: "image", id: Math.random().toString(36).substring(7), width: 103, height: 69},
}

export const image = () => {
  return sampleImages[Math.floor(Math.random() * sampleImages.length)];
}
export const imageP = () => {
  const selectedImages = sampleImages.filter(image => image.prop === "p");
  return selectedImages[Math.floor(Math.random() * selectedImages.length)];
}
export const imageL = () => {
  const selectedImages = sampleImages.filter(image => image.prop === "l");
  return selectedImages[Math.floor(Math.random() * selectedImages.length)];
}
export const imageS = () => {
  const selectedImages = sampleImages.filter(image => image.prop === "s");
  return selectedImages[Math.floor(Math.random() * selectedImages.length)];
}


export const videoL = {filename_disk: "/static/videos/sampleL.mp4", type: "video", id: Math.random().toString(36).substring(7), width: 1080, height: 1080};
export const videoP = {filename_disk: "/static/videos/sampleP.mp4", type: "video", id: Math.random().toString(36).substring(7), width: 1080, height: 1080};

export const tag = (tag = "Gesundheitswesen") => {
  const id = Math.random().toString(36).substring(7);
  return {
    caption: tag || 'Tag' + id,
    id
  }
}

export const projectCategory = (name) => {
  const tName = name || 'Name' + Math.random().toString(36).substring(7);
  return {  

    name: tName,
    media_items: [imageP()],
    feature: imageL(),
    header: tName,
    subheader: 'Subheader ' + tName,
    content_main: [],
    link: "/projects/" + tName,
  }
}

export const location = (no = 1, name, sub) => {
  const coords = [{ mapx: 42.75, mapy: 110.25}, {mapx: 96.75, mapy: 110.25}];
  const id = no; // Math.random().toString(36).substring(7);
  return {
    id,
    image: imageP(),
    main_content: [],
    name: name || 'Name ' + id,
    sub: sub || 'Sub ' + id,
    ...{ mapx: Math.floor(Math.random() * (99 - 42 + 1)) + 42, mapy: Math.floor(Math.random() * (120 - 60 + 1))}
  }
}

export const grid ={
  id: 1,
  type: "large",
  category: "Projekte",
}

export const dataList = [
  {
    caption: "Team Tragwerksplanung",
    text: "<p>Vorname Nachname<br/>Vorname Nachname<br/>Vorname Nachname<br/>Vorname Nachname<br/>Vorname Nachname<br/>Vorname Nachname<br/></p>"
  },
  {
    caption: "Wettbewerb",
    text: "<p>2016, 1. Preis</p>"
  },
  {
    caption: "Bruttogeschossfläche",
    text: "a. 15.100 m<sup>2</sup>"
  },
  {
    caption: "Auftraggeber",
    text: "Universität für Bodenkultur Wien (BOKU)"
  },
  {
    caption: "Integrale Planung mit BIM",
    text: "ATP architekten ingenieure (Architektur, Tragwerksplanung) in ARGE mit iC Consulenten (Haustechnik, Stahlwasserbau)"
  }
];


export const button = (type = "simple") => {

  if (type === 'simple') return {
    addClasses: null,
    icon_first: null,
    icon_last: "caret-right",
    caption: null,
    link: null
  }

  if (type === 'full') return {
    addClasses: "ButtonClass",
    icon_first: null,
    icon_last: "caret-right",
    caption: "My Button",
    link: "http://www.atp.ag"
  }

  if (type === 'newsletter') return {
    addClasses: "ButtonClass",
    icon_first: "envelope-open",
    icon_last: null,
    caption: "Zum Newsletter anmelden",
    link: "ModalOverlay"
  }
      
};

export const sectionHeader = {
  id: 2,
  buttons: [button()],
  category: "Standorte",
  header: "In Europa Karriere machen",
  subheader1: null,
  subheader2: null,
  paragraph: null,
  languages_code: { code: "de-DE", name: "Deutsch", direction: "ltr" },
};


export const cta = {
  id: 2,
  button: button(),
  type: "grey",
  category: "Standorte",
  header: "Header",
  subheader: "Header",
  paragraph: "Paragraph",
  languages_code: { code: "de-DE", name: "Deutsch", direction: "ltr" },
};

export const featureLarge = {
  button: button("simple"),
  image: imageP(),
  category: 'Some Category',
  header: 'My header is here',
  subheader: 'This is the subheader',
  ASTRO_TYPE: 'feature_large'
}

export const feature = {
  button: [button("simple")],
  image: imageP(),
  category: 'Projekt',
  icon: '',
  date: '07.05.2024',
  header: 'Markas-Headquarter',
  subheader: 'Luft nach unten: Gut proportionierter Baukörper kann nach unten &bdquo;mitwachsen&ldquo;',
  ASTRO_TYPE: 'feature',
  tags: [tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag(),tag()]
}

export const teaser = {
  button: [button("simple")],
  bgcolor: null,
  type: 'text-image',
  logo_image: Object.values(logoImages)[Math.floor(Math.random() * Object.values(logoImages).length)],
  media_items: [imageP()],
  category: 'Vision',
  header: 'Wir wollen die Welt mit unseren Gebäuden <a href="#" target="_self" class="text-red hover:underline">besser machen</a>.',
  subheader: 'Die gebaute Umwelt zu gestalten. <a href="/de/aktuelles" class="text-red hover:underline" target="_self">Emissionsfrei</a> Zukunftssicher. Lebenswert. Daraus entstand eine Vision.',
  subheader2: 'Die gebaute Umwelt zu gestalten. <a href="/de/aktuelles" class="text-red hover:underline" target="_self">Emissionsfrei</a>. Zukunftssicher. <span class="text-red hover:underline">Lebenswert</span>. Daraus entstand eine <a href="/de/aktuelles/publikationen" class="text-red hover:underline" target="_self">Vision</a>.',
  ASTRO_TYPE: 'teaser',
}

export const jobVacanciesTeaser = {...teaser, type: "image-text"};

export const linkSliderItems = () => {
  return [{name: "Aachen", link:"#"},{name: "Berlin", link:"#"},{name: "Bremervörde", link:"#"},{name: "Budapest", link:"#"},{name: "Eschlikon", link:"#"},{name: "Essen", link:"#"},{name: "Frankfurt", link:"#"},{name: "Hamburg", link:"#"},{name: "Innsbruck", link:"#"},{name: "Karlsruhe", link:"#"},{name: "Krakau", link:"#"},{name: "Lausanne", link:"#"},{name: "Moskau", link:"#"},{name: "München", link:"#"},{name: "Nürnberg", link:"#"},{name: "Recklinghausen", link:"#"},{name: "Rostock", link:"#"},{name: "Stuttgart", link:"#"},{name: "Wien", link:"#"},{name: "Wiesbaden", link:"#"},{name: "Zagreb", link:"#"},{name: "Zürich", link:"#"}]
}

export const introText = {
  button: [button("simple")],
  type: "large",
  category: '',
  header: 'Ein Gebäude, das gut lebt, bereichert Mensch und Umwelt. Das nennen wir nachhaltig. So sind unsere Projekte.',
  subheader: '',
  paragraph: '',
  ASTRO_TYPE: 'intro_text',
}

export const coverImage = {
  ASTRO_TYPE: "cover",
  category: "Projekt", 
  header: "IN-TOWER – Vertikale Lösungen und flexible Grundrisse für knappen Wohnraum", 
  media_item: [imageL()]
}

export const coverVideo = {
  ASTRO_TYPE: "cover",
  category: "Projekt", 
  header: "IN-TOWER – Vertikale Lösungen und flexible Grundrisse für knappen Wohnraum", 
  media_item: [videoL]
}

export const quote = {
  ASTRO_TYPE: "quote",
  category: "Zitat", 
  type: "text-image",
  media_item: imageP(),
  text: "Wir können Flüsse je nach Fragestellung maßstabgerecht und naturgetreu nachbilden. In dieser Größenordnung gib es weltweit nichts Vergleichbares.", 
  author: "Prof. Helmut Habersack",
  position: "Wasserbau, Hydraulik und Fließgewässerforschung",
  location: "Location1"
}


export const author = {
  author: "Bettina May",
  position: "Head Architect und Associate, ATP Innsbruck",
  location: "Innsbruck",
  linkURL: "http://www.atp.ag",
  linkCaption: "Kontakt aufnehmen",
  bio: "Mit ihrer Expertise im Industriebau hat sie in den vergangenen 30 Jahren (davon acht Jahre bei ATP) eine Vielzahl von Projekten von der ersten Machbarkeitsstudie bis hin zur Inbetriebnahme geplant und die Realisierung begleitet. Sie ist begeistert von der interdisziplinären integralen Zusammenarbeit bei ATP architekten ingenieure und dem ATP Green Deal. Mit ihrer Expertise im Industriebau hat sie in den vergangenen 30 Jahren (davon acht Jahre bei ATP) eine Vielzahl von Projekten von der ersten Machbarkeitsstudie bis hin zur Inbetriebnahme geplant und die Realisierung begleitet. Sie ist begeistert von der interdisziplinären integralen Zusammenarbeit bei ATP architekten ingenieure und dem ATP Green Deal."
}

export const metaInfo = {
  category: "04.07.2023, Lesezeit: 2 Minuten",
  header: "Klimaneutrale Brauerei",
  subheader: "Das ATP-CO2-Tool in der Praxis",
  paragraph: "Das ATP-CO2-Tool in der Praxis Das ATP-CO2-Tool in der Praxis Das ATP-CO2-Tool in der Praxis Das ATP-CO2-Tool in der Praxis",
  button: button("simple"),
  author: author,
  tags: [tag(), tag(), tag(), tag(), tag(), tag()]
}


export const factSliderItems = {fact: [{"name":"Expertise","fact":"Integrale Planung"},{"name":"Gründung","fact":"1951"},{"name":"Standorte","fact":"13"},{"name":"Mitarbeiter:innen","fact":"1.500"}]};


//// PAGES

export const pages = (language, prefixit = true) =>{
  const langShort = language.split('-')[0];

  const prefix = prefixit ? `/${langShort}/` : '/';
  return [
    {
      params: { slug: prefixit ? prefix : undefined  },
      props: {
        id: 1,
        status: "published",
        slug: prefix ,
        layout: "home",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp",
        layout: "atp",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/kultur" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp/kultur",
        layout: "atpculture",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/organisation" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp/organisation",
        layout: "atporganisation",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/prozess" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp/prozess",
        layout: "atpprocess",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/technologie" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp/technologie",
        layout: "atptechnology",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/verantwortung" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "atp/verantwortung",
        layout: "atpresponsibility",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/standorte" },
      props: {
        id: 4,
        status: "published",
        slug: prefix + "atp/standorte",
        layout: "locations",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "atp/standort" },
      props: {
        id: 4,
        status: "published",
        slug: prefix + "atp/standort",
        layout: "location",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "leistungen" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "leistungen",
        layout: "services",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "leistungen/leistung" },
      props: {
        id: 4,
        status: "published",
        slug: prefix + "leistungen/leistung",
        layout: "servicesservice",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "projekte" },
      props: {
        id: 2,
        status: "published",
        slug: prefix + "projekte",
        layout: "projectscategories",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/projektkategorien" },
      props: {
        id: 3,
        status: "published",
        slug: prefix + "projekte/projektkategorien",
        layout: "projectscategories",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/projektkategorie" },
      props: {
        item: projectCategory("projectscategory"),
        id: 3,
        status: "published",
        slug: prefix + "projekte/projektkategorie",
        layout: "projectscategory",
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/Category1" },
      props: {
        item: projectCategory("projectscategory"),
        id: 3,
        status: "published",
        slug: prefix + "projekte/Category1",
        layout: "projectscategory",
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/category2" },
      props: {
        item: projectCategory("projectscategory"),
        id: 3,
        status: "published",
        slug: prefix + "projekte/category2",
        layout: "projectscategory",
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/category3" },
      props: {
        item: projectCategory("projectscategory"),
        id: 3,
        status: "published",
        slug: prefix + "projekte/category3",
        layout: "projectscategory",
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/category4" },
      props: {
        item: projectCategory("projectscategory"),
        id: 3,
        status: "published",
        slug: prefix + "projekte/category4",
        layout: "projectscategory",
        language,
      },
    },
    {
      params: { slug: prefix + "projekte/projekt" },
      props: {
        id: 4,
        status: "published",
        slug: prefix + "projekte/projekt",
        layout: "projectsproject",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "aktuelles" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "aktuelles",
        layout: "news",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "aktuelles/blogpost" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "aktuelles/blogpost",
        layout: "newsItem",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "aktuelles/newspost" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "aktuelles/newspost",
        layout: "newsItem",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "aktuelles/newsletter" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "aktuelles/newsletter",
        layout: "latestnewsletter",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "aktuelles/publikationen" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "aktuelles/publikationen",
        layout: "latestpublications",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "karriere",
        layout: "career",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/warumatp" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/warumatp",
        layout: "careerwhyatp",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/berufsfelder" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/berufsfelder",
        layout: "careeroccupationalfields",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/erfolgsgeschichten" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/erfolgsgeschichten",
        layout: "careersuccessstories",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/karrierechancen" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/karrierechancen",
        layout: "careercareerchances",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/einstiegslevel" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/einstiegslevel",
        layout: "careerentrylevel",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "karriere/stellenmarkt" },
      props: {
        id: 5,
        status: "published",
        slug: prefix + "karriere/stellenmarkt",
        layout: "careerjobs",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "suchergebnis" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "suchergebnis",
        layout: "searchresults",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "glossar" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "glossar",
        layout: "glossary",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "fehler" },
      props: {
        id: 1,
        status: "published",
        slug: prefix + "fehler",
        layout: "error",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "modules" },
      props: {
        id: 8,
        status: "published",
        slug: undefined,
        layout: "modules",
        content_main: [],
        translations: [],
        language,
      },
    },
    {
      params: { slug: prefix + "cd" },
      props: {
        id: 9,
        status: "published",
        slug: undefined,
        layout: "cd",
        content_main: [],
        translations: [],
        language,
      },
    },
  ];
}
